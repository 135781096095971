import React from 'react';
import { Link } from 'react-router-dom';
import '../styles.css';

function MainPage({ formData }) {
  return (
    <div className="container">
      <header>
        <div className="header-title">
          <h1>Emergency Medical Information</h1>
        </div>
      </header>

      <main>
        <section className="section">
          <div className="section-icon">👤</div>
          <div className="section-content">
            <h2>Personal Information</h2>
            <p><strong>Name:</strong> <span>{formData.name}</span></p>
            <p><strong>Date of Birth:</strong> <span>{formData.date_of_birth}</span></p>
            <p><strong>NHS ID:</strong> <span>{formData.nhs_id}</span></p>
            <p><strong>Blood Type:</strong> <span>{formData.blood_type}</span></p>
          </div>
        </section>
        
        <section className="section">
          <div className="section-icon">📞</div>
          <div className="section-content">
            <h2>Emergency Contacts</h2>
            {formData.contacts.map((contact, index) => (
              <p key={index}><strong>Contact {index + 1}:</strong> <span>{contact.name}</span> - <span>{contact.number}</span></p>
            ))}
          </div>
        </section>
        
        <section className="section">
          <div className="section-icon">💉</div>
          <div className="section-content">
            <h2>Medical History</h2>
            <p><strong>Conditions:</strong> <span>{formData.conditions}</span></p>
            <p><strong>Surgeries:</strong> <span>{formData.surgeries}</span></p>
          </div>
        </section>
        
        <section className="section">
          <div className="section-icon">💊</div>
          <div className="section-content medications-list">
            <h2>Current Medications</h2>
            <div className="medication-header">
              <p className="medication-name"><strong>Medication</strong></p>
              <p className="medication-dosage"><strong>Dosage</strong></p>
              <p className="medication-frequency"><strong>Frequency</strong></p>
            </div>
            {formData.medications.map((medication, index) => (
              <div key={index} className="medication-item">
                <p className="medication-name"><span>{medication.name}</span></p>
                <p className="medication-dosage"><span>{medication.dosage}</span></p>
                <p className="medication-frequency"><span>{medication.frequency}</span></p>
              </div>
            ))}
          </div>
        </section>
        
        <section className="section">
          <div className="section-icon">🚫</div>
          <div className="section-content">
            <h2>Allergies</h2>
            {formData.allergies.length > 0 ? (
              <ul>
                {formData.allergies.map((allergy, index) => (
                  <li key={index}><span>{allergy}</span></li>
                ))}
              </ul>
            ) : (
              <p>No allergies reported.</p>
            )}
          </div>
        </section>
        
        <section className="section">
          <div className="section-icon">📄</div>
          <div className="section-content">
            <h2>DNR Status</h2>
            <p><strong>Status:</strong> <span>{formData.dnr_status}</span></p>
            {formData.dnr_document && (
              <p>
                <a href={URL.createObjectURL(formData.dnr_document)} className="view-dnr" target="_blank" rel="noopener noreferrer">
                  View DNR Document
                </a>
              </p>
            )}
          </div>
        </section>
      </main>

      <footer>
        <p>Last Updated: <span>{formData.last_updated}</span></p>
        <button type="button">Call Emergency Contact</button>
        <Link to="/edit">
          <button type="button">Edit Information</button>
        </Link>
      </footer>
    </div>
  );
}

export default MainPage;
