import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './components/MainPage';
import EditPage from './components/EditPage';
import UserPage from './components/UserPage';
import ResetPassword from './components/ResetPassword';

function App() {
  const [formData, setFormData] = useState({
    name: '',
    date_of_birth: '',
    blood_type: '',
    nhs_id: '',
    contacts: [{ name: '', number: '' }],
    conditions: [''],  // Initialize conditions as an array with an empty string
    surgeries: [''],   // Initialize surgeries as an array with an empty string
    medications: [{ name: '', dosage: '', frequency: '' }],
    allergies: [''],
    dnr_status: 'Inactive',
    dnr_document: null,
    last_updated: 'Never',
  });
  

  return (
    <Routes>
      <Route path="/" element={<MainPage formData={formData} />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route path="/edit/:uuid" element={<EditPage formData={formData} setFormData={setFormData} />} />
      <Route path="/user/:uuid" element={<UserPage />} />  {/* Dynamic Route for User Page */}
    </Routes>
  );
}

export default App;
