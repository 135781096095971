import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles.css';
import countryCodes from '../countryCodes';

function EditPage({ formData, setFormData }) {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const [fileUploaded, setFileUploaded] = useState(false);
  const [initialData, setInitialData] = useState(null);

  const [missingFields, setMissingFields] = useState({}); // State to track missing fields

  const [formErrors, setFormErrors] = useState({});


  // Refs for each section to allow scrolling
  const personalInfoRef = useRef(null);
  const contactSectionRef = useRef(null);
  const medicationSectionRef = useRef(null);
  const allergySectionRef = useRef(null);

  const [removedMedications, setRemovedMedications] = useState([]);
  const [removedAllergies, setRemovedAllergies] = useState([]);
  const [removedConditions, setRemovedConditions] = useState([]);   // Track removed conditions
  const [removedSurgeries, setRemovedSurgeries] = useState([]);     // Track removed surgeries


  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/user/${uuid}`);
    }
  }, [isAuthenticated, navigate, uuid]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}`);
        // const response = await fetch(`http://localhost:5000/api/users/uuid/${uuid}`);

        if (!response.ok) {
          throw new Error('User not found');
        }
        const data = await response.json();

        // Ensure countryCode exists for each contact
        data.contacts = data.contacts.map(contact => ({
          ...contact,
          countryCode: contact.countryCode || '+1',
        }));

        setFormData(data);
        setInitialData(data);

        if (data.dnr_document && data.dnr_document.data) {
          setFileUploaded(true);
        } else {
          setFileUploaded(false);
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
      }
    };

    fetchUserData();
  }, [uuid, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateDateOfBirth = () => {
  const today = new Date();
  const dob = new Date(formData.date_of_birth);
  let errors = { ...formErrors }; // Clone the existing formErrors

  // Check if date_of_birth is empty or in the future
  if (!formData.date_of_birth || dob >= today) {
    errors.date_of_birth = 'Please enter a valid date of birth in the past.';
    setMissingFields((prev) => ({
      ...prev,
      personalInfo: true, // Highlight personal info section
    }));
  } else {
    delete errors.date_of_birth; // Remove the error if the date is valid
    setMissingFields((prev) => ({
      ...prev,
      personalInfo: false,
    }));
  }

  setFormErrors(errors); // Update form errors state
};

  

 // Handle input changes for medical information (conditions/surgeries)
 const handleMedicalInputChange = (index, e) => {
  const { name, value } = e.target;

  if (name === 'conditions' || name === 'surgeries') {
    const updatedList = [...formData[name]];
    updatedList[index] = value;
    setFormData({
      ...formData,
      [name]: updatedList,
    });
  } else {
    setFormData({ ...formData, [name]: value });
  }
};

// Handle adding conditions/surgeries
const handleAddMedical = (name) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    [name]: [...prevFormData[name], ''],
  }));
};

// Handle removal of conditions/surgeries
const handleRemoveMedical = (name, index) => {
  const medicalToRemove = formData[name][index];

  // Track removed conditions or surgeries
  if (name === 'conditions' && medicalToRemove) {
    setRemovedConditions([...removedConditions, medicalToRemove]);
  } else if (name === 'surgeries' && medicalToRemove) {
    setRemovedSurgeries([...removedSurgeries, medicalToRemove]);
  }

  const updatedList = formData[name].filter((_, i) => i !== index);
  setFormData({
    ...formData,
    [name]: updatedList,
  });
};

  const handleMedicationChange = (e, index, field) => {
    const { value } = e.target;
    const updatedMedications = formData.medications.map((med, i) =>
      i === index ? { ...med, [field]: value } : med
    );
    setFormData({ ...formData, medications: updatedMedications });
  };

  const handleAddMedication = () => {
    setFormData({
      ...formData,
      medications: [...formData.medications, { name: "", dosage: "", frequency: "" }]
    });
  };

  const handleRemoveMedication = (index) => {
    const medicationToRemove = formData.medications[index];
    
    // Add the removed medication to the removedMedications array
    setRemovedMedications((prev) => [...prev, medicationToRemove]);
  
    // Remove it from the formData state
    const updatedMedications = formData.medications.filter((_, i) => i !== index);
    setFormData({ ...formData, medications: updatedMedications });
  };
  

  const handleAllergyChange = (e, index, field) => {
    const { value } = e.target;

    const updatedAllergies = formData.allergies.map((allergy, i) => {
      const updatedAllergy = allergy || { name: '', comments: '' };
      return i === index ? { ...updatedAllergy, [field]: value } : allergy;
    });

    setFormData({ ...formData, allergies: updatedAllergies });
  };

  const handleAddAllergy = () => {
    setFormData({
      ...formData,
      allergies: [...formData.allergies, { name: '', comments: '' }]
    });
  };

  const handleRemoveAllergy = (index) => {
    const allergyToRemove = formData.allergies[index];

    // Add removed allergy to the list if it has a name
    if (allergyToRemove.name) {
      setRemovedAllergies([...removedAllergies, allergyToRemove]);
    }

    // Remove allergy from formData
    const updatedAllergies = formData.allergies.filter((_, i) => i !== index);
    setFormData({ ...formData, allergies: updatedAllergies });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        dnr_document: e.target.files[0],
        dnr_status: 'Active',
        remove_dnr_document: false
      }));
      setFileUploaded(true);
    }
  };

  const handleRemoveFile = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      dnr_document: null,
      dnr_status: 'Inactive',
      remove_dnr_document: true,
    }));
    setFileUploaded(false);
  };

  const handleDnrStatusChange = (e) => {
    const value = e.target.value;
    if (value === 'Inactive') {
      handleRemoveFile();
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        dnr_status: value,
      }));
      setFileUploaded(!!formData.dnr_document?.data);
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = formData.contacts.map((contact, i) =>
      i === index ? { ...contact, [field]: value } : contact
    );
    setFormData({ ...formData, contacts: updatedContacts });
  };

  const handlePhoneNumberChange = (index, countryCode, phoneNumber) => {
    if (phoneNumber.startsWith('0')) {
      phoneNumber = phoneNumber.slice(1);
    }
    const fullNumber = `${countryCode}${phoneNumber}`;
    handleContactChange(index, 'number', fullNumber);
  };

  const handleAddContact = () => {
    setFormData({
      ...formData,
      contacts: [...formData.contacts, { name: '', countryCode: '+1', number: '', relationship: '' }]
    });
  };

  const handleRemoveContact = (index) => {
    const updatedContacts = formData.contacts.filter((_, i) => i !== index);
    setFormData({ ...formData, contacts: updatedContacts });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let validationFailed = false; // Track validation errors
    let firstInvalidSection = null; // Reference for first invalid section to scroll to
    const newMissingFields = {}; // Track which fields are missing
  
    // Validate Date of Birth (if provided)
    const validateDateOfBirth = () => {
      const today = new Date();
      const dob = new Date(formData.date_of_birth);
      let errors = { ...formErrors }; // Clone the existing formErrors
  
      if (formData.date_of_birth && dob >= today) {
        // Invalid date of birth in the future
        errors.date_of_birth = 'Please enter a valid date of birth in the past.';
        setFormErrors(errors); // Update form errors state
        setMissingFields((prev) => ({
          ...prev,
          personalInfo: true, // Highlight personal info section
        }));
        if (!firstInvalidSection) firstInvalidSection = personalInfoRef;
        validationFailed = true; // Mark validation as failed
        return false;
      } else {
        // Valid date of birth
        delete errors.date_of_birth; // Clear any previous errors
        setFormErrors(errors); // Update form errors state
        setMissingFields((prev) => ({
          ...prev,
          personalInfo: false, // Clear personal info section error
        }));
        return true;
      }
    };
    
    // Run the Date of Birth validation
    validateDateOfBirth();

    // Validate Phone Numbers for Emergency Contacts
    const validPhoneNumber = /^\+?[1-9]\d{1,14}$/;
    let hasInvalidContact = false; // Flag to track if any contact is invalid
  
    formData.contacts.forEach((contact, index) => {
      if (!contact.name || !validPhoneNumber.test(contact.number)) {
        hasInvalidContact = true; // Set the flag if a contact is invalid
        if (!firstInvalidSection) firstInvalidSection = contactSectionRef; // Track first invalid section
        validationFailed = true; // Mark validation as failed
      }
    });
    
    newMissingFields.contacts = hasInvalidContact; // Highlight contacts section if any contact is invalid

    // Medication validation: Ensure medication name is provided if dosage or frequency is filled
    formData.medications.forEach((medication, index) => {
      if ((medication.dosage || medication.frequency) && !medication.name) {
        newMissingFields.medications = true;
        if (!firstInvalidSection) firstInvalidSection = medicationSectionRef;
        validationFailed = true;
      }
    });
  
    // Allergy validation: Ensure allergy name is provided if comments are filled
    formData.allergies.forEach((allergy, index) => {
      if (allergy.comments && !allergy.name) {
        newMissingFields.allergies = true;
        if (!firstInvalidSection) firstInvalidSection = allergySectionRef;
        validationFailed = true;
      }
    });

    // DNR Status validation: Ensure that if DNR status is active, a document is provided
    if (formData.dnr_status === 'Active' && !fileUploaded) {
      newMissingFields.dnr = true;
      if (!firstInvalidSection) firstInvalidSection = allergySectionRef;
      validationFailed = true;
    }
  
    // If validation fails, highlight the missing fields and scroll to the first invalid section
    if (validationFailed) {
      setMissingFields(newMissingFields);
      if (firstInvalidSection) {
        firstInvalidSection.current.scrollIntoView({ behavior: 'smooth' }); // Scroll to the first error section
      }
      return; // Stop form submission if validation fails
    }
  
    // Proceed with form submission if all validations pass
    const updatedFormData = new FormData();
  
    // Append all fields, sending blank strings where fields are empty
    updatedFormData.append('name', formData.name || '');
    updatedFormData.append('date_of_birth', formData.date_of_birth || '');
    updatedFormData.append('nhs_id', formData.nhs_id || '');
    updatedFormData.append('blood_type', formData.blood_type || '');
    updatedFormData.append('last_updated', new Date().toISOString());
    updatedFormData.append('dnr_status', formData.dnr_status || 'Inactive');
  
    // Append contacts, sending blank strings for empty fields
    formData.contacts.forEach((contact, index) => {
      updatedFormData.append(`contacts[${index}][name]`, contact.name || '');
      updatedFormData.append(`contacts[${index}][countryCode]`, contact.countryCode || '');
      updatedFormData.append(`contacts[${index}][number]`, contact.number || '');
      updatedFormData.append(`contacts[${index}][relationship]`, contact.relationship || '');
    });
  
    // Append allergies (name and comments should send blank strings if empty)
    formData.allergies.forEach((allergy, index) => {
      updatedFormData.append(`allergies[${index}][name]`, allergy.name || '');
      updatedFormData.append(`allergies[${index}][comments]`, allergy.comments || '');
    });
  
    // Append removed allergies so the server can clear them
    removedAllergies.forEach((allergy, index) => {
      updatedFormData.append(`removedAllergies[${index}]`, allergy.name || '');
    });
  
    // Append conditions, sending blank strings for empty fields
    formData.conditions.forEach((condition, index) => {
      updatedFormData.append(`conditions[${index}]`, condition || '');
    });
  
    // Append removed conditions so the server can clear them
    removedConditions.forEach((condition, index) => {
      updatedFormData.append(`removedConditions[${index}]`, condition || '');
    });
  
    // Append surgeries, sending blank strings for empty fields
    formData.surgeries.forEach((surgery, index) => {
      updatedFormData.append(`surgeries[${index}]`, surgery || '');
    });
  
    // Append removed surgeries so the server can clear them
    removedSurgeries.forEach((surgery, index) => {
      updatedFormData.append(`removedSurgeries[${index}]`, surgery || '');
    });
  
    // Handle medications: Send existing medications and handle removed medications
    formData.medications.forEach((medication, index) => {
      updatedFormData.append(`medications[${index}][name]`, medication.name || '');
      updatedFormData.append(`medications[${index}][dosage]`, medication.dosage || '');
      updatedFormData.append(`medications[${index}][frequency]`, medication.frequency || '');
    });
  
    // Append removed medications so the server can clear them
    removedMedications.forEach((medication, index) => {
      updatedFormData.append(`removedMedications[${index}]`, medication.name || '');
    });
  
    // Handle DNR document logic
    if (formData.dnr_document) {
      updatedFormData.append('dnr_document', formData.dnr_document);
    } else if (formData.remove_dnr_document) {
      updatedFormData.append('remove_dnr_document', true);
    }
  
    try {
      const response = await fetch(`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}`, {
        method: 'PUT',
        body: updatedFormData,
      });
  
      if (!response.ok) {
        throw new Error('Failed to update user');
      }
  
      console.log('User updated successfully');
      navigate(`/user/${uuid}`);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };
  
  const handleReset = () => {
    setFormData(initialData);
  };

  return (
    <div className="container">
      <header>
        <div className="header-title">
          <h1>Edit Emergency Medical Information</h1>
        </div>
      </header>
  
      <main>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          {/* Personal Information Section */}
        <section
          className={`section ${missingFields.personalInfo ? 'highlight' : ''}`}
          ref={personalInfoRef}
        >
          <div className="section-content">
            <h2>Personal Information</h2>
            {missingFields.personalInfo && (
              <p className="error-message">Please provide your name and date of birth.</p>
            )}
            {formErrors.date_of_birth && (
              <p className="error-message">{formErrors.date_of_birth}</p>
            )}
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name || ''}
              onChange={handleInputChange}
            />
            <label>Date of Birth:</label>
            <input
              type="date"
              name="date_of_birth"
              value={formData.date_of_birth ? formData.date_of_birth.split('T')[0] : ''}
              onChange={handleInputChange}
              onBlur={validateDateOfBirth} // Validation on date input blur
            />
            <label>NHS ID:</label>
            <input
              type="text"
              name="nhs_id"
              value={formData.nhs_id || ''}
              onChange={handleInputChange}
            />
            <label>Blood Type:</label>
            <select
              name="blood_type"
              value={formData.blood_type || ''}
              onChange={handleInputChange}
            >
              <option value="">Select Blood Type</option>
              <option value="A+">A+</option>
              <option value="A-">A-</option>
              <option value="B+">B+</option>
              <option value="B-">B-</option>
              <option value="O+">O+</option>
              <option value="O-">O-</option>
              <option value="AB+">AB+</option>
              <option value="AB-">AB-</option>
            </select>
          </div>
        </section>

  
          {/* Emergency Contacts Section */}
          <section
            className={`section ${missingFields.contacts ? 'highlight' : ''}`}
            ref={contactSectionRef}
          >
              <div className="section-content">
              <h2>Emergency Contacts</h2>
              {missingFields.contacts && (
                <p className="error-message">Please ensure each contact has a name and valid phone number. Remove any empty contacts</p>
              )}
              {formData.contacts.map((contact, index) => (
                <div key={index} className="contact-item">
                  <label>Contact {index + 1} Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={contact.name}
                    onChange={(e) => handleContactChange(index, 'name', e.target.value)}
                    placeholder="Contact Name"
                  />
                  <label>Contact {index + 1} Number:</label>
                  <div className="phone-number-wrapper">
                    <select
                      value={contact.countryCode || '+1'}
                      onChange={(e) => handleContactChange(index, 'countryCode', e.target.value)}
                      className="country-code-selector"
                    >
                      <option value="+44">(+44) United Kingdom</option>
                      <option value="+1">(+1) United States of America</option>
                      <option value="+971">(+971) United Arab Emirates</option>
                      {countryCodes.map(({ name, code }) => (
                        <option key={`${name}-${code}`} value={code}>
                          ({code}) {name}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      name="number"
                      value={contact.number.replace(contact.countryCode, '')}
                      onChange={(e) => handlePhoneNumberChange(index, contact.countryCode, e.target.value)}
                      placeholder="Enter phone number"
                      className="phone-number-input"
                    />
                </div>

                  <label>Contact {index + 1} Relationship:</label>
                  <input
                    type="text"
                    name="relationship"
                    value={contact.relationship || ''}
                    onChange={(e) => handleContactChange(index, 'relationship', e.target.value)}
                    placeholder="Contacts relationship to you"
                  />
                  <button type="button" onClick={() => handleRemoveContact(index)}>Remove</button>
                </div>
              ))}
              <button type="button" onClick={handleAddContact} className="add-button">
                Add Contact
              </button>
            </div>
          </section>

          

          <section className="section">
            <div className="section-content">
              <h2>Medical History</h2>

              {/* Medical Conditions Section */}
              {formData.conditions.map((condition, index) => (
                <div key={index} className="medical-item">
                  <input
                    type="text"
                    name="conditions"
                    value={condition}
                    onChange={(event) => handleMedicalInputChange(index, event)}
                    placeholder="Medical Condition"
                  />
                  <button type="button" onClick={() => handleRemoveMedical('conditions', index)}>
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => handleAddMedical('conditions')} className="add-button">
                Add Medical Condition
              </button>

              {/* Surgeries Section */}
              {formData.surgeries.map((surgery, index) => (
                <div key={index} className="medical-item">
                  <input
                    type="text"
                    name="surgeries"
                    value={surgery}
                    onChange={(event) => handleMedicalInputChange(index, event)}
                    placeholder="Surgery"
                  />
                  <button type="button" onClick={() => handleRemoveMedical('surgeries', index)}>
                    Remove
                  </button>
                </div>
              ))}
              <button type="button" onClick={() => handleAddMedical('surgeries')} className="add-button">
                Add Surgery
              </button>
            </div>
          </section>

  
          {/* Medications Section */}
          <section
            className={`section ${missingFields.medications ? 'highlight' : ''}`}
            ref={medicationSectionRef}
          >
            <div className="section-content">
              <h2>Current Medications</h2>
              {missingFields.medications && (
                <p className="error-message">Please provide a name for each medication.</p>
              )}
              <table className="table medications-table">
                <tbody>
                  {formData.medications.map((medication, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          value={medication.name}
                          onChange={(e) => handleMedicationChange(e, index, 'name')}
                          placeholder="Medication Name"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={medication.dosage}
                          onChange={(e) => handleMedicationChange(e, index, 'dosage')}
                          placeholder="Dosage"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          value={medication.frequency}
                          onChange={(e) => handleMedicationChange(e, index, 'frequency')}
                          placeholder="Frequency"
                        />
                      </td>
                      <td>
                        <button
                          type="button"
                          className="remove-button"
                          onClick={() => handleRemoveMedication(index)}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button type="button" onClick={handleAddMedication} className="add-button">
                Add Medication
              </button>
            </div>
          </section>


  
        {/* Allergies Section */}
        <section
          className={`section ${missingFields.allergies ? 'highlight' : ''}`}
          ref={allergySectionRef}
        >
          <div className="section-content">
            <h2>Allergies</h2>
            {missingFields.allergies && (
              <p className="error-message">Please provide a name for each allergy.</p>
            )}
            <table className="table">
              <tbody>
                {formData.allergies.map((allergy, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        value={allergy.name}
                        onChange={(e) => handleAllergyChange(e, index, 'name')}
                        placeholder="Allergy"
                      />
                    </td>
                    <td>
                      <textarea
                        value={allergy.comments}
                        onChange={(e) => handleAllergyChange(e, index, 'comments')}
                        placeholder="Comments"
                        rows="3"
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="remove-button"
                        onClick={() => handleRemoveAllergy(index)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button type="button" onClick={handleAddAllergy} className="add-button">
              Add Allergy
            </button>
          </div>
        </section>


          <section className={`section ${missingFields.dnr ? 'highlight' : ''}`}>
            <div className="section-content">
              <h2>Do Not Resuscitate (DNR) Status</h2>
              {missingFields.dnr && (
                <p className="error-message">Please upload a DNR document if the status is Active.</p>
              )}
              <select
                name="dnr_status"
                value={formData.dnr_status || 'Inactive'}
                onChange={handleDnrStatusChange}
              >
                <option value="Inactive">Inactive</option>
                <option value="Active">Active</option>
              </select>

              {formData.dnr_status === 'Active' && (
                <>
                  <label>Upload DNR Document:</label>
                  <input
                    type="file"
                    name="dnr_document"
                    onChange={handleFileChange}
                    disabled={formData.dnr_status !== 'Active'}
                  />
                  {fileUploaded && !missingFields.dnr && (
                      <>
                      <p>Current DNR Document: <a href={`https://medicids-backend-34db21443628.herokuapp.com/api/users/uuid/${uuid}/dnr`} target="_blank" rel="noopener noreferrer">View DNR Document</a></p>
                      {/* <p>Current DNR Document: <a href={`http://localhost:5000/api/users/uuid/${uuid}/dnr`} target="_blank" rel="noopener noreferrer">View DNR Document</a></p> */}
                      <button type="button" onClick={handleRemoveFile}>Remove File</button>
                     </>
                  )}
                </>
              )}
            </div>
          </section>


          <footer>
            <button type="button" className="reset-button" onClick={handleReset}>Reset Changes</button>
            <button type="submit" className="save-button">Save Changes</button>
          </footer>
        </form>
      </main>
    </div>
  );  
}

export default EditPage;